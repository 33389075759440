<template>
   <AmcText class="check">
      <AmcText class="d-flex justify-content-center">
         <div class="align-self-center alert alert-danger fs-1" role="alert">Yetkiniz Bulunmamaktadır.</div>
      </AmcText>
   </AmcText>
</template>

<script>
export default {
   name: 'AuthorizationCheck',
};
</script>

<style scoped lang="scss">
.check {
   height: 600px;
   padding-top: 150px;
}
</style>
